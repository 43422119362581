import React from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

interface Props {
  currentPage: number;
  totalJobs: number;
  pageSize: number;
  onPageChange: (pageNumber: number) => void;
}

const Pagination: React.FC<Props> = ({
  currentPage,
  totalJobs,
  pageSize,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalJobs / pageSize);
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  // Calculate the start and end indexes for displaying page numbers
  const startIndex =
    currentPage <= 2
      ? 0
      : currentPage >= totalPages - 1
      ? totalPages - 3
      : currentPage - 1;
  const endIndex = Math.min(startIndex + 3, totalPages);

  return (
    <>
      <div className="d-flex vh-50 justify-content-center align-items-center my-4">
        <button
          onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
          disabled={currentPage === 1}
          className="btn btn-light me-2"
        >
          <FiArrowLeft />
        </button>
        {pageNumbers.slice(startIndex, endIndex).map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            className={`btn ${
              pageNumber === currentPage
                ? "btn-primary"
                : "btn-light text-secondary"
            } me-2`}
          >
            {pageNumber}
          </button>
        ))}
        <button
          onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="btn btn-light"
        >
          <FiArrowRight />
        </button>
      </div>
    </>
  );
};

export default Pagination;
