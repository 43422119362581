import { createClient, Entry } from "contentful";
import { Document } from "@contentful/rich-text-types";

export interface Content {
  sys: {
    id: string;
    updatedAt: string;
  };
  fields: {
    headline: string;
    metadescriptions: string;
    content: Document;
    image: {
      fields: {
        description: string;
        file: {
          url: string;
        };
      };
    };
    slug: string;
  };
}

const space = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

if (!space || !accessToken) {
  throw new Error("Contentful space ID and access token must be provided.");
}

const client = createClient({
  space,
  accessToken,
});

export const fetchContent = async (): Promise<Content[]> => {
  try {
    const response = await client.getEntries({
      content_type: "microblog", // Replace with your actual content type ID
      select: [
        "sys.id",
        "sys.updatedAt",
        "fields.headline",
        "fields.metadescriptions",
        "fields.content",
        "fields.slug",
        "fields.image",
      ],
    });
    const content = response.items.map((item: Entry<any>) => ({
      sys: item.sys,
      fields: item.fields as Content["fields"],
    }));
    return content;
  } catch (error) {
    console.error("Error fetching content:", error);
    throw error;
  }
};
