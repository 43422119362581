import React from "react";
import { Link } from "react-router-dom";
import icon from "../../images/404.jpeg";

const NotFoundPage: React.FC = () => {
  return (
    <div className="container text-center mt-5">
      <div className="col-md-6 mx-auto">
        <img src={icon} alt="404 page" className="not-found" />
      </div>
      <h1>Page Not Found</h1>
      <p className="lead">Oops! The page you're looking for doesn't exist.</p>
      <p>
        Please check the URL or go back to the <Link to="/">homepage</Link>.
      </p>
    </div>
  );
};

export default NotFoundPage;
