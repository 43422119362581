import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Banner from "../CommonComponents/Banner";
import Footer from "../CommonComponents/Footer";
import JobListing from "../HomePage/JobListing";
import SearchBox from "../CommonComponents/SearchBox";
import Pagination from "../CommonComponents/Pagination"; // Import Pagination component
import SpinnerComponent from "../CommonComponents/SpinnerComponent";
import ErrorAlertComponent from "../CommonComponents/ErrorAlertComponent";
import { FiAlertOctagon, FiRefreshCcw } from "react-icons/fi";

interface Job {
  job_title: string;
  company_name: string;
  ai_category: string;
  category: string[];
  company_logo: string;
  company_url: string;
  date_posted: string;
  department: string;
  is_remote: string;
  job_url: string;
  max_salary: number | null;
  min_salary: number | null;
  skills: string[];
  slug_url: string | null;
  work_location: string[];
}

const Engineering: React.FC = () => {
  const [jobListings, setJobListings] = useState<Job[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalJobs, setTotalJobs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [tempSearchTerm, setTempSearchTerm] = useState("");
  const pageSize = 20;

  const fetchData = useCallback(async () => {
    setLoading(true);
    window.scrollTo(0, 0);
    try {
      const response = await axios.post(
        process.env.REACT_APP_HOME_JOBS_API!,
        {
          page: currentPage - 1,
          search: [tempSearchTerm],
          page_size: pageSize,
          departments: ["Engineering"],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { jobs, count } = response.data;
      setJobListings(jobs || []);
      setTotalJobs(count || 0);
      setError(null);
    } catch (error) {
      setError("Error fetching data");
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  }, [tempSearchTerm, pageSize, currentPage]);

  useEffect(() => {
    fetchData();
  }, [currentPage, tempSearchTerm, fetchData]);

  useEffect(() => {
    if (tempSearchTerm !== "") {
      setCurrentPage(1);
    }
  }, [tempSearchTerm]);

  const handleSearch = (term: string) => {
    setTempSearchTerm(term);
    setCurrentPage(1); // Reset page to 1 when performing a new search
  };

  return (
    <div className="mt">
      <div>
        <Banner title="Engineering Jobs" />
        {loading ? (
          <SpinnerComponent animation="border" variant="primary" />
        ) : error ? (
          <ErrorAlertComponent error={error} />
        ) : (
          <>
            <div className="vh-50">
              <div className="dark py-2">
                <h3 className="text-center text-white">
                  {totalJobs} Jobs Found
                </h3>
                <SearchBox onSearch={handleSearch} />
                {tempSearchTerm && (
                  <div className="container">
                    <p className="text-center text-white my-3">
                      Results for "{tempSearchTerm}"
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="container  mt-2">
              {jobListings.length !== 0 ? (
                jobListings.map((job, index) => (
                  <JobListing key={index} job={job} />
                ))
              ) : (
                <div className="d-flex py-5  flex-column justify-content-center align-items-center">
                  <FiAlertOctagon size={30} color="tomato" />
                  <p className="text-danger">No results found.</p>
                  <a href="/engineering_jobs">
                    <FiRefreshCcw /> Refresh
                  </a>
                </div>
              )}
            </div>
            {/* Pagination component */}
            <Pagination
              currentPage={currentPage}
              totalJobs={totalJobs}
              pageSize={pageSize}
              onPageChange={setCurrentPage}
            />
          </>
        )}
      </div>
      <Footer showFilter />
    </div>
  );
};

export default Engineering;
