import React, { useState, useEffect } from "react";
import { fetchContent, Content } from "./ContentfulService";
import { FiArrowUpRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";
import SpinnerComponent from "../CommonComponents/SpinnerComponent";
import ErrorAlertComponent from "../CommonComponents/ErrorAlertComponent";

const ContentList: React.FC = () => {
  const [headingList, setHeadingList] = useState<Content[]>([]);
  const [filteredHeadings, setFilteredHeadings] = useState<Content[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await fetchContent();
        setHeadingList(data);
        setFilteredHeadings(data);
        setLoading(false);
        setError(null);
      } catch (error) {
        setLoading(false);
        setError("Error Fetching Data");
        console.error("Error fetching content:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = headingList.filter((content) => {
      const headline = content.fields.headline || "";
      const metaDescription = content.fields.metadescriptions || "";

      return (
        headline.toLowerCase().includes(searchQuery.toLowerCase()) ||
        metaDescription.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setFilteredHeadings(filtered);
  }, [searchQuery, headingList]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    console.log("Search query:", event.target.value); // Log the search query for debugging
  };

  return (
    <>
      {loading ? (
        <SpinnerComponent animation="border" variant="primary" />
      ) : error ? (
        <ErrorAlertComponent error={error} />
      ) : (
        <div className="container ht">
          <div className="page">
            <h6 className="mb-4 text-info text-center">Recent Articles</h6>
            <div className="search mb-5">
              <input
                type="text"
                placeholder="Search Articles"
                value={searchQuery}
                onChange={handleSearch}
                className="form-control mb-4 px-4 mx-auto"
              />
            </div>

            <div className="blog-list">
              {filteredHeadings.length > 0 ? (
                filteredHeadings.map((content, index) => (
                  <div
                    className="blog-item"
                    key={index}
                    style={{ animationDelay: `${index * 0.1}s` }}
                  >
                    <div className="card-body">
                      <div className="d-flex flex-wrap">
                        <div className="col-md-4">
                          <img
                            className="blogSmall"
                            src={
                              content && content.fields.image
                                ? `https:${content.fields.image.fields.file.url}`
                                : "https://i.postimg.cc/DZdyxp5Q/ai-1.png"
                            }
                            alt={content.fields.headline}
                          />
                        </div>
                        <div className="col-md-8 blogText">
                          <h4>{content.fields.headline}</h4>
                          <p className="small">
                            {content.fields.metadescriptions}
                          </p>
                          <span style={{ color: "#999", fontSize: 14 }}>
                            {moment(content.sys.updatedAt).format(
                              "hh:mm a | MMMM DD, YYYY"
                            )}
                          </span>

                          <Link
                            to={`/blog/${encodeURIComponent(
                              content.fields.slug
                            )}`}
                            className="d-flex d-block align-items-center my-2 flex-wrap"
                          >
                            Read More <FiArrowUpRight size={20} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No match found</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContentList;
