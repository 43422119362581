import React from "react";
import Banner from "../CommonComponents/Banner";
import Footer from "../CommonComponents/Footer";
import ContentList from "../BlogPage/ContentList";

const Blog: React.FC = () => {
  return (
    <div className="mt">
      <Banner title="Blog Section" />
      <ContentList />
      <Footer />
    </div>
  );
};

export default Blog;
