import React, { useState, useEffect } from "react";
import {
  FiArrowLeft,
  FiArrowUpRight,
  FiCompass,
  FiHome,
  FiMapPin,
} from "react-icons/fi";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import Banner from "../CommonComponents/Banner";
import Footer from "../CommonComponents/Footer";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import axios from "axios";
import ErrorAlertComponent from "../CommonComponents/ErrorAlertComponent";
import SpinnerComponent from "../CommonComponents/SpinnerComponent";
import { Helmet } from "react-helmet";

interface JobData {
  company_name: string;
  company_url: string;
  job_url: string;
  work_location: string[];
  max_salary: number | null;
  min_salary: number | null;
  job_title: string;
  department: string;
  ai_category: string;
  company_logo: string;
  category: string[];
  is_remote: string;
  skills: string[];
  slug_url: string;
  description: string;
  date_posted: string;
}

const JobDetails: React.FC = () => {
  const location = useLocation();
  const { slug } = useParams<{ slug: string }>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [jobData, setJobData] = useState<JobData | null>(null);

  const jobUrl = location.pathname; // Get the current URL path
  const jobName = jobUrl.substring(jobUrl.lastIndexOf("/") + 1); // Extract the job name
  const value = slug ? `/jobs${slug}` : `/${jobName}`;

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchDescription = async () => {
      setLoading(true);

      try {
        const response = await axios.get(
          `https://s20fminsmh.execute-api.us-east-1.amazonaws.com/jobs${value}`
        );
        setJobData(response.data);
      } catch (error) {
        setError("Failed to fetch job details");
        console.log("Failed to fetch job details", error);
      } finally {
        setLoading(false);
      }
    };
    if (value) {
      fetchDescription();
    }
  }, [value]);

  // Structured data in JSON-LD format
  const structuredData = jobData
    ? {
        "@context": "https://schema.org",
        "@type": "JobPosting",
        hiringOrganization: {
          "@type": "Organization",
          name: jobData.company_name || "Unknown Company",
          logo: jobData.company_logo || "https://example.com/default-logo.png",
        },
        title: jobData.job_title || "Job Title",
        datePosted: jobData.date_posted || new Date().toISOString(),
        validThrough: moment().add(30, "days").toISOString(), // Assuming job is valid for 30 days from now
        employmentType: jobData.is_remote ? "Remote" : "OnSite", // Use schema.org values for employmentType
        jobLocation: {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            streetAddress: jobData.work_location[0] || "N/A", // Assuming the first location is the street address
            addressLocality:
              jobData.work_location.slice(1, 2).join(", ") || "N/A", // Assuming second part is locality
            addressRegion: "CA", // Use actual region if available
            addressCountry: "US", // Assuming United States; adjust if necessary
          },
        },
        baseSalary: {
          "@type": "MonetaryAmount",
          currency: "USD",
          value: {
            "@type": "QuantitativeValue",
            value: jobData.max_salary || 0,
            unitText: "YEAR", // Assuming annual salary
          },
        },
        description: jobData.description || "No description available.",
      }
    : {};

  return (
    <div className="mt">
      <Helmet>
        <title>
          AIJobster - Find Your Dream Job, remote ai jobs in AI companies.
        </title>
        {jobData && (
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        )}
      </Helmet>
      <Banner title={jobData?.company_name || "Job Details"} />
      {loading ? (
        <SpinnerComponent animation="border" variant="primary" />
      ) : error ? (
        <ErrorAlertComponent error={error} />
      ) : (
        <div className="container">
          <a href={"/"} className="py-4 d-flex align-items-center">
            <FiArrowLeft className="me-2" /> View More Jobs
          </a>
          {jobData && (
            <div className="col-md-10 mt-2 mx-auto">
              <span className="text-secondary fw-normal">Job Details</span>
              <h3 className="text-capitalize text-info">{jobData.job_title}</h3>
              <div className="d-flex justify-content-between flex-wrap align-items-center">
                <div className="d-flex">
                  <small className="text-info d-flex align-items-center">
                    <FiCompass className="me-1" />
                    {jobData.is_remote ? "Remote" : "On-Site"}
                  </small>
                  {jobData.min_salary && jobData.max_salary ? (
                    <small className="text-info ms-1">
                      | ${jobData.min_salary}k - ${jobData.max_salary}k
                    </small>
                  ) : null}
                </div>
                <p className="text-secondary m-0 small">
                  Published: {moment(jobData.date_posted).fromNow()}
                </p>
              </div>

              <hr></hr>
              <div className="row">
                <div className="col-lg-9 mt-2 pe-5 detailspage">
                  <h6>Job Description:</h6>
                  {jobData.description ? (
                    <p
                      className="fw-light"
                      dangerouslySetInnerHTML={{ __html: jobData.description }}
                    ></p>
                  ) : (
                    <>
                      <p className="text-secondary">
                        No description found yet. You can click below to review
                        the description directly on the website
                      </p>
                      <a
                        href={jobData.job_url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-center"
                      >
                        <button className="btn w-25 mt-5 btn-sm btn-primary">
                          Apply Now
                        </button>
                      </a>
                    </>
                  )}
                </div>
                <div className="col-lg-3 h-50 bg-light rounded">
                  <div className="d-flex flex-column my-4">
                    {jobData.company_logo ? (
                      <img
                        style={{
                          width: 80,
                          height: 80,
                          objectFit: "contain",
                          textAlign: "center",
                          borderRadius: 5,
                        }}
                        className="d-block mx-auto"
                        src={jobData.company_logo}
                        alt={jobData.company_name}
                      />
                    ) : (
                      <FiHome />
                    )}
                    <a
                      href={jobData.company_url}
                      target="_blank"
                      rel="noreferrer"
                      className="p-1 text-center"
                    >
                      {jobData.company_name} <FiArrowUpRight />
                    </a>
                    {jobData.work_location.length > 0 && (
                      <small className="text-capitalize text-center p-1">
                        <FiMapPin className="me-1" />
                        {jobData.work_location.join(", ")}
                      </small>
                    )}
                    <hr></hr>
                    <div className="d-flex justify-content-center my-2">
                      <FacebookShareButton
                        url={jobData.job_url}
                        className="me-2"
                      >
                        <FacebookIcon size={30} round />
                      </FacebookShareButton>
                      <LinkedinShareButton
                        url={jobData.job_url}
                        className="me-2"
                      >
                        <LinkedinIcon size={30} round />
                      </LinkedinShareButton>
                      <WhatsappShareButton
                        url={jobData.job_url}
                        separator=":: "
                        className="me-2"
                      >
                        <WhatsappIcon size={30} round />
                      </WhatsappShareButton>
                      <TwitterShareButton
                        url={jobData.job_url}
                        className="me-2"
                      >
                        <XIcon size={30} round />
                      </TwitterShareButton>
                    </div>
                    <a
                      href={jobData.job_url}
                      target="_blank"
                      rel="noreferrer"
                      className="text-center"
                    >
                      <button className="btn w-50 mt-5 btn-sm btn-primary">
                        Apply Now
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default JobDetails;
