import React, { useState } from "react";
import { FiArrowUpRight, FiBriefcase, FiGlobe, FiMapPin } from "react-icons/fi";
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface Job {
  ai_category: string;
  category: string[];
  company_logo: string;
  company_name: string;
  company_url: string;
  date_posted: string;
  department: string;
  is_remote: string;
  job_title: string;
  job_url: string;
  max_salary: number;
  min_salary: number;
  skills: string[];
  slug_url: string | null;
  work_location: string[];
}

interface Company {
  ai_category: string[];
  company_name: string;
  company_url: string;
  employees: string;
  fund: string;
  fund_stage: string;
  hq: string;
  year_founded: number;
}

interface Department {
  department: string;
  jobs: Job[];
}

interface ListItemProps {
  jobs: Department[];
  company: Company;
}

const ListItem: React.FC<ListItemProps> = ({ jobs, company }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>(jobs[0]?.department);

  const totalCount = jobs.reduce(
    (acc, department) => acc + department.jobs.length,
    0
  );

  const handleNavigation = (job: Job) => {
    navigate(`/jobs/${job.company_name}${job.slug_url}`, {
      state: { value: job.slug_url },
    });
  };

  return (
    <div>
      <div className="company-details">
        <div className="d-flex py-3">
          <h1 className="text-capitalize">{company.company_name}</h1>
          <a href={company.company_url} target="_blank" rel="noreferrer">
            <FiArrowUpRight size={30} color="#0cf" className="mx-2" />
          </a>
        </div>
        <table className="table table-responsive table-striped">
          <tbody>
            <tr>
              <th>Year Founded</th>
              <th>Employees</th>
              <th>Funding</th>
              <th>Funding Stage</th>
              <th>HQ</th>
              <th>AI Category</th>
            </tr>
            <tr>
              <td className="text-capitalize">{company.year_founded}</td>
              <td className="text-capitalize">{company.employees}</td>
              <td className="text-capitalize">{company.fund}</td>
              <td className="text-capitalize">{company.fund_stage}</td>
              <td className="text-capitalize">{company.hq}</td>
              <td className="text-capitalize">
                {company.ai_category.join(", ")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h6 className="py-4">
        Open Job Positions: <strong>{totalCount} jobs</strong>
      </h6>
      <div className="tabs mb-4">
        {jobs.map((department, index) => (
          <button
            key={index}
            className={activeTab === department.department ? "active" : ""}
            onClick={() => setActiveTab(department.department)}
          >
            {department.department}{" "}
            <small>({department.jobs.length} jobs</small>)
          </button>
        ))}
      </div>
      {jobs.map((department, index) => (
        <div
          key={index}
          style={{
            display: activeTab === department.department ? "block" : "none",
          }}
        >
          {department.jobs.map((job, jobIndex) => (
            <div key={jobIndex} className="list">
              <div className="job-details">
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <h6 className="text-capitalize text-primary m-0">
                    {job.job_title}
                  </h6>
                  <span className="text-secondary m-0 small">
                    {moment(job.date_posted).fromNow()}
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <span className="italic my-1">{job.department}</span>
                  <div className="d-flex align-items-center">
                    <FiGlobe size={18} color="#0cf" />
                    <small className="ms-1">
                      {job.is_remote ? "Remote" : "On-site"}
                    </small>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <h6 className="text-capitalize fw-bold">
                    {job.company_name}
                  </h6>
                </div>
                <div className="d-flex flex-wrap">
                  {job.skills.map((skill, index) => (
                    <span
                      key={index}
                      className="me-2 mb-1 green px-2 small text-capitalize text-dark"
                    >
                      {skill}
                    </span>
                  ))}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div>
                  <>
                    {job.work_location && job.work_location[0] !== "" ? (
                      <div className="me-2 mb-2">
                        <FiMapPin size={22} className="me-1" />
                        <small className="text-capitalize">
                          {job.work_location.join(", ")}
                        </small>
                      </div>
                    ) : null}
                  </>
                  {job.min_salary && (
                    <div className="d-flex">
                      <FiBriefcase size={21} />
                      <span className="m-0 mx-2">
                        ${job.min_salary}k - ${job.max_salary}k
                      </span>
                    </div>
                  )}
                </div>
                <img
                  src={job.company_logo}
                  alt={job.company_name}
                  className="company-logo"
                />
              </div>
              {job.slug_url ? (
                <button
                  onClick={() => handleNavigation(job)}
                  className="btn btn-sm  btn-primary mt-3"
                >
                  Apply Now
                </button>
              ) : (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-sm btn-primary mt-3"
                  href={job.job_url}
                >
                  Apply Now
                </a>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ListItem;
