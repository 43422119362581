import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import SpinnerComponent from '../CommonComponents/SpinnerComponent';
import ErrorAlertComponent from '../CommonComponents/ErrorAlertComponent';
import { FiArrowUpCircle } from 'react-icons/fi';

const JobBackend: React.FC = () => {
    const [jobs, setJobs] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const fetchData = useCallback(async () => {
        setLoading(true);
        window.scrollTo(0, 0);
        try {
          const response = await axios.post(
            process.env.REACT_APP_HOME_JOBS_API!,
            {
              page_size: 240,
              page: 0, 
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
    
          const { jobs } = response.data;
          setJobs(jobs || []);
        
          setError(null);
        } catch (error) {
          setError("Error fetching data");
          console.error("Error fetching data:", error);
        }
        setLoading(false);
      }, []);

      useEffect(() => {
       fetchData()
    }, [fetchData]);

      // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  

    return (
        <div className='mt'>
            {loading ? (
          <SpinnerComponent animation="border" variant="primary" />
        ) : error ? (
          <ErrorAlertComponent error={error} />
        ) : (
          <div className='p-1'>
            <p>Job Listings JSON</p>
            <pre>{JSON.stringify(jobs, null, 2)}</pre>
            <FiArrowUpCircle size={40}  className='fixed-bottom d-block ms-auto m-3 text-primary'  onClick={scrollToTop}/>
          </div>)}
        </div>
    );
};

export default JobBackend;
