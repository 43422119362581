export const aiBenefitsPageContent = [
  {
    title: "Automation",
    description:
      "AI enables the automation of repetitive and mundane tasks, improving efficiency and allowing humans to focus on more complex and creative aspects of their work.",
  },
  {
    title: "Data Analysis",
    description:
      "AI can analyze vast amounts of data quickly and accurately, extracting valuable insights and patterns that may be challenging for humans to discern.",
  },
  {
    title: "Personalization",
    description:
      "AI is instrumental in creating personalized experiences in technology, such as personalized recommendations in e-commerce, content suggestions in streaming services, and targeted advertising.",
  },
  {
    title: "Decision Making",
    description:
      "AI systems can process information rapidly and make data-driven decisions, enhancing the decision-making processes in various industries, from finance to healthcare.",
  },
  {
    title: "Predictive Analytics",
    description:
      "AI algorithms can predict future trends and outcomes based on historical data, aiding businesses in making informed decisions and mitigating risks.",
  },
  {
    title: "Natural Language Processing (NLP)",
    description:
      "AI-powered NLP enables machines to understand, interpret, and generate human-like language, facilitating advancements in virtual assistants, chatbots, and language translation services.",
  },
  {
    title: "Image and Speech Recognition",
    description:
      "AI enables accurate recognition of images and speech, leading to applications in facial recognition, medical imaging, and voice-activated systems.",
  },
  {
    title: "Medical Advances",
    description:
      "AI is used in healthcare for diagnostics, drug discovery, and personalized medicine, contributing to improved patient care and outcomes.",
  },
  {
    title: "Autonomous Vehicles",
    description:
      "AI plays a crucial role in the development of self-driving cars, enhancing safety and efficiency in transportation.",
  },
  {
    title: "Cybersecurity",
    description:
      "AI can detect and prevent cyber threats by analyzing patterns and anomalies in real-time, providing robust security measures.",
  },
  {
    title: "Innovations in Creativity",
    description:
      "AI is being utilized in creative fields such as art, music, and design, contributing to the generation of new and unique content.",
  },
];

export const aiJobsContent = [
  {
    title: "High Demand",
    description:
      "The increasing integration of AI in various industries has created a high demand for skilled professionals in AI-related roles. This demand is expected to continue growing as AI technologies advance.",
  },
  {
    title: "Competitive Salaries",
    description:
      "Due to the specialized nature of AI skills and the demand for expertise, AI professionals often command competitive salaries. Companies are willing to invest in top talent to stay at the forefront of technological advancements.",
  },
  {
    title: "Diverse Career Opportunities",
    description:
      "AI jobs span a wide range of domains, including machine learning, natural language processing, computer vision, robotics, and data science. This diversity allows professionals to choose from various specializations based on their interests and skills.",
  },
  {
    title: "Innovation and Creativity",
    description:
      "Working in AI often involves tackling challenging problems and developing innovative solutions. AI professionals have the opportunity to contribute to cutting-edge research and create technologies that can have a profound impact on society.",
  },
  {
    title: "Global Impact",
    description:
      "AI has the potential to address global challenges in areas like healthcare, climate change, and education. AI professionals have the opportunity to contribute to solutions that can improve the quality of life on a global scale.",
  },
  {
    title: "Continuous Learning",
    description:
      "The field of AI is dynamic and rapidly evolving. AI professionals are continually exposed to new technologies, methodologies, and research findings, fostering a culture of continuous learning and professional development.",
  },
  {
    title: "Remote Work Opportunities",
    description:
      "Many AI jobs can be performed remotely, providing flexibility for professionals to work from different locations. This flexibility can enhance work-life balance and job satisfaction.",
  },
  {
    title: "Collaborative Environments",
    description:
      "AI projects often require interdisciplinary collaboration, bringing together experts from various fields such as computer science, mathematics, and domain-specific areas. This collaborative environment fosters diverse perspectives and encourages teamwork.",
  },
  {
    title: "Career Stability",
    description:
      "As AI becomes increasingly integral to various industries, professionals in AI-related roles can enjoy career stability. The demand for AI expertise is likely to persist as organizations continue to invest in AI solutions.",
  },
  {
    title: "Entrepreneurial Opportunities",
    description:
      "AI professionals have the chance to explore entrepreneurial ventures, whether by founding their own startups or contributing to innovation within existing companies. The startup ecosystem in AI is vibrant, offering opportunities for those who want to shape the future of the industry.",
  },
];

export const locationList = [
  {
    id: 1,
    title: "San Francisco",
    slug: "san francisco",
  },
  {
    id: 2,
    title: "New York",
    slug: "new york",
  },
  {
    id: 3,
    title: "Paris",
    slug: "paris",
  },
  {
    id: 4,
    title: "Seattle",
    slug: "seattle",
  },
  {
    id: 5,
    title: "Boston",
    slug: "boston",
  },
  {
    id: 6,
    title: "London",
    slug: "london",
  },
  {
    id: 7,
    title: "Dublin",
    slug: "dublin",
  },
  {
    id: 8,
    title: "Berlin",
    slug: "berlin",
  },
  {
    id: 9,
    title: "Austin",
    slug: "austin",
  },
  {
    id: 10,
    title: "Toronto",
    slug: "toronto",
  },
];

export const jobTypes = [
  {
    id: 1,
    title: "Remote Jobs",
    slug: "remote",
  },
  {
    id: 2,
    title: "Frontend Jobs",
    slug: "frontend",
  },
  {
    id: 3,
    title: "Backend Jobs",
    slug: "backend",
  },
];

export const aiLinks = [
  { name: "Coursera", url: "https://www.coursera.org/" },
  { name: "edX", url: "https://www.edx.org/" },
  { name: "Kaggle", url: "https://www.kaggle.com/" },
  { name: "DeepLearning.AI", url: "https://www.deeplearning.ai/" },
  { name: "Towards Data Science", url: "https://towardsdatascience.com/" },
  { name: "Google AI", url: "https://ai.google/" },
  { name: "Microsoft AI", url: "https://www.microsoft.com/en-us/ai" },
  { name: "IBM Watson", url: "https://www.ibm.com/watson" },
  { name: "Amazon AI", url: "https://aws.amazon.com/machine-learning/" },
  { name: "Facebook AI", url: "https://ai.facebook.com/" },
  { name: "OpenAI", url: "https://www.openai.com/" },
  { name: "Baidu AI", url: "https://ai.baidu.com/" },
  {
    name: "Alibaba Cloud AI",
    url: "https://www.alibabacloud.com/solutions/ai",
  },
  { name: "Intel AI", url: "https://www.intel.ai/" },
  { name: "NVIDIA AI", url: "https://www.nvidia.com/en-us/ai-computing/" },
  { name: "Apple Machine Learning", url: "https://machinelearning.apple.com/" },
  { name: "Stanford AI Lab", url: "https://ai.stanford.edu/" },
  { name: "MIT AI Lab", url: "https://www.csail.mit.edu/" },
  { name: "DeepMind", url: "https://deepmind.com/" },
  { name: "UC Berkeley AI Research", url: "https://bair.berkeley.edu/" },
  { name: "Carnegie Mellon AI", url: "https://www.cmu.edu/ai/" },
  { name: "Oxford AI", url: "https://www.cs.ox.ac.uk/research/ai/" },
  { name: "University of Toronto AI", url: "https://vectorinstitute.ai/" },
  { name: "Georgia Tech AI", url: "https://www.cc.gatech.edu/ai" },
  { name: "Allen Institute for AI", url: "https://allenai.org/" },
  { name: "Element AI", url: "https://www.elementai.com/" },
  { name: "Salesforce AI Research", url: "https://einstein.ai/" },
  {
    name: "Toyota Research Institute AI",
    url: "https://www.tri.global/robotics/",
  },
  { name: "Adobe AI", url: "https://www.adobe.com/ai.html" },
  { name: "Sony AI", url: "https://www.sony.com/ai" },
  { name: "Uber AI", url: "https://www.uber.com/us/en/atg/" },
  { name: "Nokia Bell Labs AI", url: "https://www.bell-labs.com/ai/" },
  { name: "Samsung AI", url: "https://www.samsung.com/semiconductor/ai/" },
  {
    name: "Accenture AI",
    url: "https://www.accenture.com/us-en/services/applied-intelligence-index",
  },
  { name: "LG Electronics AI", url: "https://www.lg.com/us/ai" },
  {
    name: "Cisco AI",
    url: "https://www.cisco.com/c/en/us/solutions/enterprise-networks/artificial-intelligence.html",
  },
  {
    name: "AI in Healthcare",
    url: "https://www.nih.gov/research-training/medical-research-initiatives/artificial-intelligence",
  },
  { name: "AI in Finance", url: "https://www.bis.org/press/p210614.htm" },
  {
    name: "AI in Education",
    url: "https://en.unesco.org/themes/education-emergencies/artificial-intelligence-education",
  },
  { name: "AI Ethics", url: "https://www.cde.org.nz/content/ai-ethics" },
];

export const quotes = [
  {
    quote:
      "There are 10 types of people in the world: those who understand binary and those who don't.",
    author: "Unknown",
  },
  {
    quote:
      "I would love to change the world, but they won’t give me the source code.",
    author: "Unknown",
  },
  {
    quote: "In a world without fences and walls, who needs Gates and Windows?",
    author: "Unknown",
  },
  {
    quote: "To err is human, but to really foul things up you need a computer.",
    author: "Paul R. Ehrlich",
  },
  {
    quote: "Artificial intelligence is no match for natural stupidity.",
    author: "Unknown",
  },
  {
    quote: "Programmer: A machine that turns coffee into code.",
    author: "Unknown",
  },
  {
    quote:
      "Computers are like air conditioners. They work fine until you start opening windows.",
    author: "Unknown",
  },
  {
    quote: "I'm not anti-social; I'm just not user friendly.",
    author: "Unknown",
  },
  {
    quote:
      "The Internet: where men are men, women are men, and children are FBI agents.",
    author: "Unknown",
  },
  {
    quote:
      "A computer once beat me at chess, but it was no match for me at kickboxing.",
    author: "Emo Philips",
  },
  {
    quote: "Any sufficiently advanced bug is indistinguishable from a feature.",
    author: "Rich Kulawiec",
  },
  {
    quote:
      "I changed my password to 'incorrect'. So whenever I forget, it will tell me 'Your password is incorrect'.",
    author: "Unknown",
  },
  {
    quote:
      "The code that is the hardest to debug is the code that you know cannot possibly be wrong.",
    author: "Unknown",
  },
  {
    quote: "Beware of the software engineer who carries a screwdriver.",
    author: "Robert Paul",
  },
  {
    quote:
      "I'm not a great programmer; I'm just a good programmer with great habits.",
    author: "Kent Beck",
  },
  {
    quote:
      "Software and cathedrals are much the same – first we build them, then we pray.",
    author: "Unknown",
  },
  {
    quote: "It's not a bug – it's an undocumented feature.",
    author: "Unknown",
  },
  {
    quote: "Hardware: The parts of a computer that can be kicked.",
    author: "Jeff Pesis",
  },
  {
    quote: "The only problem with troubleshooting is that trouble shoots back.",
    author: "Unknown",
  },
  {
    quote:
      "The nice thing about standards is that you have so many to choose from.",
    author: "Andrew S. Tanenbaum",
  },
  {
    quote:
      "Unix is user-friendly. It's just very selective about who its friends are.",
    author: "Unknown",
  },
  {
    quote: "If at first you don’t succeed; call it version 1.0.",
    author: "Unknown",
  },
  {
    quote: "I'm sorry for what I said when I was debugging.",
    author: "Unknown",
  },
  {
    quote:
      "Programming is like sex. One mistake and you have to support it for the rest of your life.",
    author: "Michael Sinz",
  },
  {
    quote:
      "The trouble with programmers is that you can never tell what a programmer is doing until it’s too late.",
    author: "Seymour Cray",
  },
  {
    quote:
      "A good programmer is someone who always looks both ways before crossing a one-way street.",
    author: "Doug Linder",
  },
  {
    quote:
      "Don't worry if it doesn't work right. If everything did, you'd be out of a job.",
    author: "Mosher's Law of Software Engineering",
  },
  { quote: "Deleted code is debugged code.", author: "Jeff Sickel" },
  {
    quote:
      "The best performance improvement is the transition from the nonworking state to the working state.",
    author: "J. Osterhout",
  },
  { quote: "There is no Ctrl-Z in life.", author: "Unknown" },
  {
    quote:
      "Computers are good at following instructions, but not at reading your mind.",
    author: "Donald Knuth",
  },
  { quote: "There’s no place like 127.0.0.1.", author: "Unknown" },
  {
    quote: "I have a joke on programming, but it only works on my machine.",
    author: "Unknown",
  },
  {
    quote:
      "In theory, there is no difference between theory and practice. But, in practice, there is.",
    author: "Jan L. A. van de Snepscheut",
  },
  {
    quote:
      "Algorithm: Word used by programmers when they do not want to explain what they did.",
    author: "Unknown",
  },
  {
    quote:
      "A SQL query goes into a bar, walks up to two tables and asks, 'Can I join you?'",
    author: "Unknown",
  },
  {
    quote: "First, solve the problem. Then, write the code.",
    author: "John Johnson",
  },
  {
    quote: "To understand recursion, you must first understand recursion.",
    author: "Unknown",
  },
  {
    quote: "Programming is thinking, not typing.",
    author: "Casey Patton",
  },
  {
    quote: "Talk is cheap. Show me the code.",
    author: "Linus Torvalds",
  },
  {
    quote: "It works on my machine.",
    author: "Unknown",
  },
  {
    quote:
      "Give a man a program, frustrate him for a day. Teach a man to program, frustrate him for a lifetime.",
    author: "Unknown",
  },
  {
    quote:
      "The best method for accelerating a computer is the one that boosts it by 9.8 m/s^2.",
    author: "Unknown",
  },
  {
    quote: "Weeks of programming can save you hours of planning.",
    author: "Unknown",
  },
  {
    quote:
      "The problem with quick and dirty is that dirty remains long after quick has been forgotten.",
    author: "Steve McConnell",
  },
  {
    quote:
      "Measuring programming progress by lines of code is like measuring aircraft building progress by weight.",
    author: "Bill Gates",
  },
  {
    quote:
      "Software and cathedrals are much the same – first we build them, then we pray.",
    author: "Sam Redwine",
  },
  {
    quote:
      "Most software today is very much like an Egyptian pyramid with millions of bricks piled on top of each other, with no structural integrity, but just done by brute force and thousands of slaves.",
    author: "Alan Kay",
  },
  {
    quote:
      "The cheapest, fastest, and most reliable components are those that aren’t there.",
    author: "Gordon Bell",
  },
  {
    quote:
      "The most important property of a program is whether it accomplishes the intention of its user.",
    author: "C.A.R. Hoare",
  },
  {
    quote:
      "A primary cause of complexity is that software vendors uncritically adopt almost any feature that users want.",
    author: "Niklaus Wirth",
  },
  {
    quote:
      "If you don’t handle [exceptions], we shut your application down. That dramatically increases the reliability of the system.",
    author: "Anders Hejlsberg",
  },
  {
    quote:
      "Most good programmers do programming not because they expect to get paid or get adulation by the public, but because it is fun to program.",
    author: "Linus Torvalds",
  },
  {
    quote: "Good code is its own best documentation.",
    author: "Steve McConnell",
  },
  {
    quote:
      "You can’t have great software without a great team, and most software teams behave like dysfunctional families.",
    author: "Jim McCarthy",
  },
  {
    quote:
      "It’s hardware that makes a machine fast. It’s software that makes a fast machine slow.",
    author: "Craig Bruce",
  },
  {
    quote:
      "If Java had true garbage collection, most programs would delete themselves upon execution.",
    author: "Robert Sewell",
  },
  {
    quote:
      "Programming languages, like pizzas, come in only two sizes: too big and too small.",
    author: "Richard E. Pattis",
  },
  {
    quote:
      "The best thing about a boolean is even if you are wrong, you are only off by a bit.",
    author: "Unknown",
  },
  {
    quote:
      "Some people, when confronted with a problem, think “I know, I'll use regular expressions.” Now they have two problems.",
    author: "Jamie Zawinski",
  },
  {
    quote:
      "The most effective debugging tool is still careful thought, coupled with judiciously placed print statements.",
    author: "Brian Kernighan",
  },
  {
    quote:
      "The key to performance is elegance, not battalions of special cases.",
    author: "Jon Bentley",
  },
  {
    quote:
      "If debugging is the process of removing bugs, then programming must be the process of putting them in.",
    author: "Edsger Dijkstra",
  },
  {
    quote:
      "The act of describing a programming language in a language other than that language itself is highly dangerous.",
    author: "Robert W. Floyd",
  },
  {
    quote:
      "Computer science education cannot make anybody an expert programmer any more than studying brushes and pigment can make somebody an expert painter.",
    author: "Eric Raymond",
  },
  {
    quote: "The best way to get a project done faster is to start sooner.",
    author: "Jim Highsmith",
  },
  {
    quote: "Simplicity is prerequisite for reliability.",
    author: "Edsger W. Dijkstra",
  },
  {
    quote:
      "The bearing of a child takes nine months, no matter how many women are assigned.",
    author: "Fred Brooks",
  },
  {
    quote:
      "I think Microsoft named .Net so it wouldn’t show up in a Unix directory listing.",
    author: "Oktal",
  },
  {
    quote:
      "Always code as if the guy who ends up maintaining your code will be a violent psychopath who knows where you live.",
    author: "Martin Golding",
  },
  {
    quote:
      "Every time you write a comment, you should grimace and feel the failure of your ability of expression.",
    author: "Robert C. Martin",
  },
  {
    quote: "They don't make bugs like Bunny anymore.",
    author: "Olav Mjelde",
  },
  {
    quote: "Before software can be reusable it first has to be usable.",
    author: "Ralph Johnson",
  },
  {
    quote:
      "When a program is being tested, it is too late to make design changes.",
    author: "Geoffrey James",
  },
  {
    quote: "Code never lies, comments sometimes do.",
    author: "Ron Jeffries",
  },
  {
    quote:
      "When debugging, novices insert corrective code; experts remove defective code.",
    author: "Richard Pattis",
  },
  {
    quote: "No code is faster than no code.",
    author: "Kevlin Henney",
  },
  {
    quote:
      "One of my most productive days was throwing away 1000 lines of code.",
    author: "Ken Thompson",
  },
  {
    quote: "Programming is not easy like Sunday morning, it is silent poetry.",
    author: "Unknown",
  },
  {
    quote:
      "Any code of your own that you haven’t looked at for six or more months might as well have been written by someone else.",
    author: "Eagleson’s law",
  },
  {
    quote: "Premature optimization is the root of all evil.",
    author: "Donald Knuth",
  },
  {
    quote:
      "In programming, the hard part isn’t solving problems, but deciding what problems to solve.",
    author: "Paul Graham",
  },
  {
    quote:
      "In software, the most beautiful code, the most beautiful functions, and the most beautiful programs are sometimes not there at all.",
    author: "Jon Bentley",
  },
  {
    quote:
      "I don't care if it works on your machine! We are not shipping your machine!",
    author: "Vidiu Platon",
  },
  {
    quote:
      "A language that doesn’t affect the way you think about programming is not worth knowing.",
    author: "Alan Perlis",
  },
  {
    quote: "Controlling complexity is the essence of computer programming.",
    author: "Brian Kernighan",
  },
  {
    quote: "A good way to stay flexible is to write less code.",
    author: "Pragmatic Programmer",
  },
  {
    quote: "A software project goes wrong because it’s plain hard.",
    author: "Unknown",
  },
  {
    quote:
      "Python's a drop-in replacement for BASIC in the sense that Optimus Prime is a drop-in replacement for a truck.",
    author: "Cory Dodt",
  },
  {
    quote: "Code is like humor. When you have to explain it, it’s bad.",
    author: "Cory House",
  },
  {
    quote:
      "Don’t worry if it doesn’t work right. If everything did, you’d be out of a job.",
    author: "Mosher’s Law of Software Engineering",
  },
  {
    quote: "Testing can show the presence of errors, but not their absence.",
    author: "Edsger W. Dijkstra",
  },
  {
    quote:
      "Learning to program has no more to do with designing interactive software than learning to touch type has to do with writing poetry.",
    author: "Ted Nelson",
  },
  {
    quote:
      "Perfection is achieved not when there is nothing more to add, but when there is nothing left to take away.",
    author: "Antoine de Saint-Exupery",
  },
  {
    quote: "You can’t have great software without a great team.",
    author: "Jim McCarthy",
  },
  {
    quote: "If you think your users are idiots, only idiots will use it.",
    author: "Linus Torvalds",
  },
  {
    quote:
      "In order to understand recursion, one must first understand recursion.",
    author: "Stephen Hawking",
  },
  {
    quote:
      "Programming isn’t about what you know; it’s about what you can figure out.",
    author: "Chris Pine",
  },
  {
    quote: "The best error message is the one that never shows up.",
    author: "Thomas Fuchs",
  },
  {
    quote: "It’s not a bug – it’s an undocumented feature.",
    author: "Anonymous",
  },
  {
    quote: "If at first you don’t succeed; call it version 1.0.",
    author: "Unknown",
  },
  {
    quote:
      "How rare it is that maintaining someone else’s code is easy! Most often, it’s harder than writing it from scratch.",
    author: "Anonymous",
  },
  {
    quote:
      "Sometimes it’s better to leave something alone, to pause, and that’s very true of programming.",
    author: "Joyce Wheeler",
  },
  {
    quote:
      "When debugging, novices insert corrective code; experts remove defective code.",
    author: "Richard Pattis",
  },
  {
    quote:
      "If builders built buildings the way programmers wrote programs, then the first woodpecker that came along would destroy civilization.",
    author: "Gerald Weinberg",
  },
  {
    quote: "Fast, Good, Cheap: Pick two.",
    author: "Anonymous",
  },
  {
    quote:
      "Programming is the art of telling another human what one wants the computer to do.",
    author: "Donald Knuth",
  },
  {
    quote:
      "It’s not that I’m so smart, it’s just that I stay with problems longer.",
    author: "Albert Einstein",
  },
  {
    quote: "The more code you write, the more you have to maintain.",
    author: "J. Ostera",
  },
  {
    quote: "Code never lies; comments sometimes do.",
    author: "Ron Jeffries",
  },
  {
    quote: "Make it work, make it right, make it fast.",
    author: "Kent Beck",
  },
  {
    quote:
      "Any fool can write code that a computer can understand. Good programmers write code that humans can understand.",
    author: "Martin Fowler",
  },
  {
    quote:
      "Programs must be written for people to read, and only incidentally for machines to execute.",
    author: "Harold Abelson",
  },
  {
    quote:
      "Programming isn’t about what you know; it’s about what you can figure out.",
    author: "Chris Pine",
  },
  {
    quote: "First, solve the problem. Then, write the code.",
    author: "John Johnson",
  },
  {
    quote: "Fix the cause, not the symptom.",
    author: "Steve Maguire",
  },
  {
    quote:
      "Optimism is an occupational hazard of programming: feedback is the treatment.",
    author: "Kent Beck",
  },
  {
    quote:
      "When to use iterative development? You should use iterative development only on projects that you want to succeed.",
    author: "Martin Fowler",
  },
  {
    quote: "Simplicity is the soul of efficiency.",
    author: "Austin Freeman",
  },
  {
    quote: "Before software can be reusable it first has to be usable.",
    author: "Ralph Johnson",
  },
  {
    quote: "Make it work, make it right, make it fast.",
    author: "Kent Beck",
  },
  {
    quote:
      "The best thing about a boolean is even if you are wrong, you are only off by a bit.",
    author: "Anonymous",
  },
  {
    quote:
      "Sometimes it pays to stay in bed on Monday, rather than spending the rest of the week debugging Monday’s code.",
    author: "Dan Salomon",
  },
  {
    quote:
      "There are only two kinds of programming languages: those people always bitch about and those nobody uses.",
    author: "Bjarne Stroustrup",
  },
  {
    quote: "Testing leads to failure, and failure leads to understanding.",
    author: "Burt Rutan",
  },
  {
    quote:
      "The greatest performance improvement of all is when a system goes from not-working to working.",
    author: "John Ousterhout",
  },
  {
    quote:
      "Happiness is not something ready made. It comes from your own actions.",
    author: "Dalai Lama",
  },
  {
    quote:
      "Measuring programming progress by lines of code is like measuring aircraft building progress by weight.",
    author: "Bill Gates",
  },
  {
    quote: "If at first you don’t succeed; call it version 1.0.",
    author: "Unknown",
  },
  {
    quote: "Good software, like good wine, takes time.",
    author: "Joel Spolsky",
  },
  {
    quote:
      "The most reliable component of any system is the one you leave out.",
    author: "Gordon Bell",
  },
  {
    quote: "The best way to predict the future is to invent it.",
    author: "Alan Kay",
  },
  {
    quote:
      "If you don't have time to do it right, when will you have time to do it over?",
    author: "John Wooden",
  },
  {
    quote:
      "The six Phases of a project: Enthusiasm, Disillusionment, Panic, Search for the Guilty, Punishment of the Innocent, Praise for the Non-participants.",
    author: "Unknown",
  },
  {
    quote: "Don't comment bad code - rewrite it.",
    author: "Brian W. Kernighan",
  },
  {
    quote: "It's not a bug - it's an undocumented feature.",
    author: "Anonymous",
  },
  {
    quote: "Nine people can’t make a baby in a month.",
    author: "Fred Brooks",
  },
  {
    quote:
      "The first 90 percent of the code accounts for the first 90 percent of the development time. The remaining 10 percent of the code accounts for the other 90 percent of the development time.",
    author: "Tom Cargill",
  },
  {
    quote:
      "The difference between theory and practice is that in theory, there is no difference between theory and practice.",
    author: "Richard Moore",
  },
  {
    quote: "I have not failed. I’ve just found 10,000 ways that won’t work.",
    author: "Thomas Edison",
  },
  {
    quote:
      "The best thing about a boolean is even if you are wrong, you are only off by a bit.",
    author: "Anonymous",
  },
  {
    quote: "If you think it’s simple, then you have misunderstood the problem.",
    author: "Bjarne Stroustrup",
  },
  {
    quote:
      "I'm not a great programmer; I'm just a good programmer with great habits.",
    author: "Kent Beck",
  },
  {
    quote: "First, solve the problem. Then, write the code.",
    author: "John Johnson",
  },
  {
    quote: "Experience is the name everyone gives to their mistakes.",
    author: "Oscar Wilde",
  },
  {
    quote:
      "Sometimes it pays to stay in bed on Monday, rather than spending the rest of the week debugging Monday’s code.",
    author: "Dan Salomon",
  },
  {
    quote:
      "When to use iterative development? You should use iterative development only on projects that you want to succeed.",
    author: "Martin Fowler",
  },
  {
    quote: "If at first you don’t succeed; call it version 1.0.",
    author: "Unknown",
  },
  {
    quote:
      "Programming isn’t about what you know; it’s about what you can figure out.",
    author: "Chris Pine",
  },
  {
    quote: "Simplicity is prerequisite for reliability.",
    author: "Edsger W. Dijkstra",
  },
  {
    quote:
      "When debugging, novices insert corrective code; experts remove defective code.",
    author: "Richard Pattis",
  },
  {
    quote: "Code never lies, comments sometimes do.",
    author: "Ron Jeffries",
  },
  {
    quote:
      "It’s not that I’m so smart, it’s just that I stay with problems longer.",
    author: "Albert Einstein",
  },
  {
    quote: "The best way to get a project done faster is to start sooner.",
    author: "Jim Highsmith",
  },
  {
    quote: "The best error message is the one that never shows up.",
    author: "Thomas Fuchs",
  },
  {
    quote:
      "The most important property of a program is whether it accomplishes the intention of its user.",
    author: "C.A.R. Hoare",
  },
  {
    quote: "You can't have great software without a great team.",
    author: "Jim McCarthy",
  },
  {
    quote:
      "If debugging is the process of removing software bugs, then programming must be the process of putting them in.",
    author: "Edsger Dijkstra",
  },
  {
    quote:
      "Don't worry if it doesn't work right. If everything did, you'd be out of a job.",
    author: "Mosher's Law of Software Engineering",
  },
  {
    quote:
      "Measuring programming progress by lines of code is like measuring aircraft building progress by weight.",
    author: "Bill Gates",
  },
  {
    quote:
      "The best performance improvement is the transition from the nonworking state to the working state.",
    author: "J. Osterhout",
  },

  {
    quote:
      "Programming today is a race between software engineers striving to build bigger and better idiot-proof programs, and the Universe trying to produce bigger and better idiots. So far, the Universe is winning.",
    author: "Rick Cook",
  },
  {
    quote: "You can’t have great software without a great team.",
    author: "Jim McCarthy",
  },
  {
    quote:
      "In theory, there is no difference between theory and practice. But, in practice, there is.",
    author: "Jan L. A. van de Snepscheut",
  },
  {
    quote:
      "The trouble with programmers is that you can never tell what a programmer is doing until it’s too late.",
    author: "Seymour Cray",
  },
  {
    quote: "The more code you write, the more you have to maintain.",
    author: "J. Ostera",
  },
  {
    quote: "To err is human, but to really foul things up you need a computer.",
    author: "Paul Ehrlich",
  },
  {
    quote:
      "Any code of your own that you haven’t looked at for six or more months might as well have been written by someone else.",
    author: "Eagleson’s law",
  },
  {
    quote:
      "There are only two kinds of programming languages: those people always complain about and those nobody uses.",
    author: "Bjarne Stroustrup",
  },
  {
    quote:
      "One of my most productive days was throwing away 1000 lines of code.",
    author: "Ken Thompson",
  },
  {
    quote:
      "I think Microsoft named .Net so it wouldn’t show up in a Unix directory listing.",
    author: "Oktal",
  },
  {
    quote: "Don't comment bad code - rewrite it.",
    author: "Brian W. Kernighan",
  },
  {
    quote: "Code is like humor. When you have to explain it, it’s bad.",
    author: "Cory House",
  },
  {
    quote:
      "Learning to write programs stretches your mind and helps you think better, creates a way of thinking about things that I think is helpful in all domains.",
    author: "Bill Gates",
  },
  {
    quote:
      "The first 90 percent of the code accounts for the first 90 percent of the development time. The remaining 10 percent of the code accounts for the other 90 percent of the development time.",
    author: "Tom Cargill",
  },
  {
    quote:
      "In order to understand recursion, one must first understand recursion.",
    author: "Stephen Hawking",
  },
  {
    quote:
      "Programming is the art of algorithm design and the craft of debugging errant code.",
    author: "Ellen Ullman",
  },
  {
    quote: "First, solve the problem. Then, write the code.",
    author: "John Johnson",
  },
  {
    quote: "Testing can show the presence of bugs, but not their absence.",
    author: "Edsger W. Dijkstra",
  },
  {
    quote: "If at first you don’t succeed; call it version 1.0.",
    author: "Unknown",
  },
  {
    quote:
      "If builders built buildings the way programmers wrote programs, then the first woodpecker that came along would destroy civilization.",
    author: "Gerald Weinberg",
  },
  {
    quote:
      "The best thing about a boolean is even if you are wrong, you are only off by a bit.",
    author: "Anonymous",
  },

  {
    quote:
      "Computers are good at following instructions, but not at reading your mind.",
    author: "Donald Knuth",
  },
  {
    quote:
      "Don't worry if it doesn't work right. If everything did, you'd be out of a job.",
    author: "Mosher's Law of Software Engineering",
  },
  {
    quote: "Nine people can't make a baby in a month.",
    author: "Fred Brooks",
  },
  {
    quote: "The best way to predict the future is to implement it.",
    author: "David Heinemeier Hansson",
  },

  {
    quote:
      "The best performance improvement is the transition from the nonworking state to the working state.",
    author: "J. Osterhout",
  },
  {
    quote: "Controlling complexity is the essence of computer programming.",
    author: "Brian Kernighan",
  },
  {
    quote:
      "The most reliable component of any system is the one you leave out.",
    author: "Gordon Bell",
  },
  {
    quote:
      "Any fool can write code that a computer can understand. Good programmers write code that humans can understand.",
    author: "Martin Fowler",
  },
  {
    quote: "Code never lies, comments sometimes do.",
    author: "Ron Jeffries",
  },
  {
    quote: "Fast, Good, Cheap: Pick two.",
    author: "Anonymous",
  },
  {
    quote: "If you think it’s simple, then you have misunderstood the problem.",
    author: "Bjarne Stroustrup",
  },
  {
    quote:
      "I’m not a great programmer; I’m just a good programmer with great habits.",
    author: "Kent Beck",
  },
  {
    quote: "The best way to get a project done faster is to start sooner.",
    author: "Jim Highsmith",
  },
  {
    quote:
      "The bearing of a child takes nine months, no matter how many women are assigned.",
    author: "Fred Brooks",
  },
  {
    quote:
      "Any code of your own that you haven’t looked at for six or more months might as well have been written by someone else.",
    author: "Eagleson’s law",
  },
  {
    quote: "Good software, like good wine, takes time.",
    author: "Joel Spolsky",
  },
  {
    quote: "The more code you write, the more you have to maintain.",
    author: "J. Ostera",
  },
  {
    quote:
      "In order to understand recursion, one must first understand recursion.",
    author: "Stephen Hawking",
  },
  {
    quote: "The best way to predict the future is to invent it.",
    author: "Alan Kay",
  },
  {
    quote: "It’s not a bug – it’s an undocumented feature.",
    author: "Anonymous",
  },
  {
    quote:
      "Happiness is not something ready made. It comes from your own actions.",
    author: "Dalai Lama",
  },
  {
    quote:
      "The trouble with programmers is that you can never tell what a programmer is doing until it’s too late.",
    author: "Seymour Cray",
  },

  {
    quote: "The more code you write, the more you have to maintain.",
    author: "J. Ostera",
  },
  {
    quote:
      "Perfection is achieved not when there is nothing more to add, but when there is nothing left to take away.",
    author: "Antoine de Saint-Exupery",
  },
  {
    quote:
      "The most important property of a program is whether it accomplishes the intention of its user.",
    author: "C.A.R. Hoare",
  },
  {
    quote: "The best error message is the one that never shows up.",
    author: "Thomas Fuchs",
  },
  {
    quote: "If you think your users are idiots, only idiots will use it.",
    author: "Linus Torvalds",
  },
  {
    quote:
      "How rare it is that maintaining someone else’s code is easy! Most often, it’s harder than writing it from scratch.",
    author: "Anonymous",
  },
  {
    quote: "Simplicity is the soul of efficiency.",
    author: "Austin Freeman",
  },
  {
    quote:
      "The most reliable component of any system is the one you leave out.",
    author: "Gordon Bell",
  },

  {
    quote: "Code never lies; comments sometimes do.",
    author: "Ron Jeffries",
  },
  {
    quote: "Simplicity is prerequisite for reliability.",
    author: "Edsger W. Dijkstra",
  },
  {
    quote:
      "Optimism is an occupational hazard of programming: feedback is the treatment.",
    author: "Kent Beck",
  },
  {
    quote: "Testing leads to failure, and failure leads to understanding.",
    author: "Burt Rutan",
  },
  {
    quote: "Fix the cause, not the symptom.",
    author: "Steve Maguire",
  },
  {
    quote: "Controlling complexity is the essence of computer programming.",
    author: "Brian Kernighan",
  },
  {
    quote: "Premature optimization is the root of all evil.",
    author: "Donald Knuth",
  },
  {
    quote: "A good way to stay flexible is to write less code.",
    author: "Pragmatic Programmer",
  },
  {
    quote: "A software project goes wrong because it’s plain hard.",
    author: "Unknown",
  },
  {
    quote:
      "Any fool can write code that a computer can understand. Good programmers write code that humans can understand.",
    author: "Martin Fowler",
  },
  {
    quote:
      "Programming today is a race between software engineers striving to build bigger and better idiot-proof programs, and the Universe trying to produce bigger and better idiots. So far, the Universe is winning.",
    author: "Rick Cook",
  },
  {
    quote:
      "One of my most productive days was throwing away 1000 lines of code.",
    author: "Ken Thompson",
  },
  {
    quote:
      "The best performance improvement is the transition from the nonworking state to the working state.",
    author: "J. Osterhout",
  },
  {
    quote: "If at first you don’t succeed; call it version 1.0.",
    author: "Unknown",
  },
  {
    quote:
      "When debugging, novices insert corrective code; experts remove defective code.",
    author: "Richard Pattis",
  },
  {
    quote: "I have not failed. I’ve just found 10,000 ways that won’t work.",
    author: "Thomas Edison",
  },
  {
    quote:
      "It’s not that I’m so smart, it’s just that I stay with problems longer.",
    author: "Albert Einstein",
  },
  {
    quote: "Simplicity is the soul of efficiency.",
    author: "Austin Freeman",
  },
  {
    quote:
      "The best thing about a boolean is even if you are wrong, you are only off by a bit.",
    author: "Anonymous",
  },
  {
    quote: "The more code you write, the more you have to maintain.",
    author: "J. Ostera",
  },
  {
    quote:
      "Measuring programming progress by lines of code is like measuring aircraft building progress by weight.",
    author: "Bill Gates",
  },
  {
    quote:
      "The six Phases of a project: Enthusiasm, Disillusionment, Panic, Search for the Guilty, Punishment of the Innocent, Praise for the Non-participants.",
    author: "Unknown",
  },
  {
    quote: "The best way to predict the future is to invent it.",
    author: "Alan Kay",
  },

  {
    quote: "Nine people can’t make a baby in a month.",
    author: "Fred Brooks",
  },
  {
    quote: "It’s not a bug – it’s an undocumented feature.",
    author: "Anonymous",
  },
  {
    quote:
      "It’s not that I’m so smart, it’s just that I stay with problems longer.",
    author: "Albert Einstein",
  },
  {
    quote: "Good software, like good wine, takes time.",
    author: "Joel Spolsky",
  },
  {
    quote: "A software project goes wrong because it’s plain hard.",
    author: "Unknown",
  },
  {
    quote: "You can’t have great software without a great team.",
    author: "Jim McCarthy",
  },
  {
    quote: "Controlling complexity is the essence of computer programming.",
    author: "Brian Kernighan",
  },
  {
    quote: "If you think it’s simple, then you have misunderstood the problem.",
    author: "Bjarne Stroustrup",
  },
  {
    quote:
      "If debugging is the process of removing bugs, then programming must be the process of putting them in.",
    author: "Edsger Dijkstra",
  },
  {
    quote: "Code is like humor. When you have to explain it, it’s bad.",
    author: "Cory House",
  },
  {
    quote:
      "When debugging, novices insert corrective code; experts remove defective code.",
    author: "Richard Pattis",
  },

  {
    quote: "The more code you write, the more you have to maintain.",
    author: "J. Ostera",
  },
  {
    quote: "Don't comment bad code - rewrite it.",
    author: "Brian W. Kernighan",
  },
  {
    quote:
      "Programming is the art of algorithm design and the craft of debugging errant code.",
    author: "Ellen Ullman",
  },
  {
    quote:
      "The most important property of a program is whether it accomplishes the intention of its user.",
    author: "C.A.R. Hoare",
  },

  {
    quote: "The best way to predict the future is to invent it.",
    author: "Alan Kay",
  },
  {
    quote: "You can't have great software without a great team.",
    author: "Jim McCarthy",
  },
  {
    quote:
      "Any fool can write code that a computer can understand. Good programmers write code that humans can understand.",
    author: "Martin Fowler",
  },
  {
    quote: "Controlling complexity is the essence of computer programming.",
    author: "Brian Kernighan",
  },

  {
    quote: "Good software, like good wine, takes time.",
    author: "Joel Spolsky",
  },
  {
    quote:
      "The six Phases of a project: Enthusiasm, Disillusionment, Panic, Search for the Guilty, Punishment of the Innocent, Praise for the Non-participants.",
    author: "Unknown",
  },
  {
    quote: "Don't comment bad code - rewrite it.",
    author: "Brian W. Kernighan",
  },
  {
    quote:
      "If debugging is the process of removing software bugs, then programming must be the process of putting them in.",
    author: "Edsger Dijkstra",
  },
  {
    quote:
      "Any code of your own that you haven’t looked at for six or more months might as well have been written by someone else.",
    author: "Eagleson’s law",
  },
  {
    quote:
      "The best performance improvement is the transition from the nonworking state to the working state.",
    author: "J. Osterhout",
  },
  {
    quote: "Code never lies, comments sometimes do.",
    author: "Ron Jeffries",
  },
  {
    quote: "Good software, like good wine, takes time.",
    author: "Joel Spolsky",
  },
  {
    quote:
      "If you don't have time to do it right, when will you have time to do it over?",
    author: "John Wooden",
  },
  {
    quote:
      "Don't worry if it doesn't work right. If everything did, you'd be out of a job.",
    author: "Mosher's Law of Software Engineering",
  },
  {
    quote:
      "The most reliable component of any system is the one you leave out.",
    author: "Gordon Bell",
  },
  {
    quote:
      "The best thing about a boolean is even if you are wrong, you are only off by a bit.",
    author: "Anonymous",
  },
  {
    quote:
      "Measuring programming progress by lines of code is like measuring aircraft building progress by weight.",
    author: "Bill Gates",
  },
  {
    quote: "A good way to stay flexible is to write less code.",
    author: "Pragmatic Programmer",
  },

  {
    quote: "Good software, like good wine, takes time.",
    author: "Joel Spolsky",
  },
  {
    quote:
      "The first 90 percent of the code accounts for the first 90 percent of the development time. The remaining 10 percent of the code accounts for the other 90 percent of the development time.",
    author: "Tom Cargill",
  },

  {
    quote: "A good way to stay flexible is to write less code.",
    author: "Pragmatic Programmer",
  },
  {
    quote: "Fast, Good, Cheap: Pick two.",
    author: "Anonymous",
  },

  {
    quote: "A good way to stay flexible is to write less code.",
    author: "Pragmatic Programmer",
  },
  {
    quote: "The more code you write, the more you have to maintain.",
    author: "J. Ostera",
  },
  {
    quote: "Nine people can’t make a baby in a month.",
    author: "Fred Brooks",
  },
  {
    quote: "If at first you don’t succeed; call it version 1.0.",
    author: "Unknown",
  },

  {
    quote:
      "Any fool can write code that a computer can understand. Good programmers write code that humans can understand.",
    author: "Martin Fowler",
  },

  {
    quote: "A good way to stay flexible is to write less code.",
    author: "Pragmatic Programmer",
  },
  {
    quote: "Good software, like good wine, takes time.",
    author: "Joel Spolsky",
  },
  {
    quote: "The best way to predict the future is to invent it.",
    author: "Alan Kay",
  },
  {
    quote: "The more code you write, the more you have to maintain.",
    author: "J. Ostera",
  },
  {
    quote:
      "The best thing about a boolean is even if you are wrong, you are only off by a bit.",
    author: "Anonymous",
  },
  {
    quote:
      "If you don't have time to do it right, when will you have time to do it over?",
    author: "John Wooden",
  },

  {
    quote: "A good way to stay flexible is to write less code.",
    author: "Pragmatic Programmer",
  },
  {
    quote: "Good software, like good wine, takes time.",
    author: "Joel Spolsky",
  },
  {
    quote: "Fast, Good, Cheap: Pick two.",
    author: "Anonymous",
  },
  {
    quote:
      "The first 90 percent of the code accounts for the first 90 percent of the development time. The remaining 10 percent of the code accounts for the other 90 percent of the development time.",
    author: "Tom Cargill",
  },

  {
    quote: "A good way to stay flexible is to write less code.",
    author: "Pragmatic Programmer",
  },
  {
    quote: "The more code you write, the more you have to maintain.",
    author: "J. Ostera",
  },
  {
    quote:
      "Any fool can write code that a computer can understand. Good programmers write code that humans can understand.",
    author: "Martin Fowler",
  },
  {
    quote:
      "The first 90 percent of the code accounts for the first 90 percent of the development time. The remaining 10 percent of the code accounts for the other 90 percent of the development time.",
    author: "Tom Cargill",
  },
  {
    quote: "Nine people can't make a baby in a month.",
    author: "Fred Brooks",
  },
  {
    quote: "The best way to predict the future is to implement it.",
    author: "David Heinemeier Hansson",
  },
  {
    quote:
      "Optimism is an occupational hazard of programming: feedback is the treatment.",
    author: "Kent Beck",
  },
  {
    quote: "The more code you write, the more you have to maintain.",
    author: "J. Ostera",
  },
  {
    quote: "Fast, Good, Cheap: Pick two.",
    author: "Anonymous",
  },
  {
    quote: "The more code you write, the more you have to maintain.",
    author: "J. Ostera",
  },
  {
    quote:
      "Optimism is an occupational hazard of programming: feedback is the treatment.",
    author: "Kent Beck",
  },
  {
    quote: "The best way to predict the future is to implement it.",
    author: "David Heinemeier Hansson",
  },
  {
    quote: "Good software, like good wine, takes time.",
    author: "Joel Spolsky",
  },
  {
    quote: "Don't comment bad code - rewrite it.",
    author: "Brian W. Kernighan",
  },
];
