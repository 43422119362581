import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Banner from "../CommonComponents/Banner";
import Footer from "../CommonComponents/Footer";
import { fetchContent, Content } from "./ContentfulService";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { FiArrowLeft } from "react-icons/fi";
import { Helmet } from "react-helmet";

const BlogDetails: React.FC = () => {
  const { heading } = useParams<{ heading?: string }>();
  const decodedHeading = heading || "";
  const [blogContent, setBlogContent] = useState<Content | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      window.scrollTo(0, 0);
      try {
        const data = await fetchContent();
        const selectedBlog = data.find(
          (content) =>
            content.fields.slug === decodeURIComponent(decodedHeading)
        );
        setBlogContent(selectedBlog || null);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };
    fetchData();
  }, [decodedHeading]);

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text: React.ReactNode) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text: React.ReactNode) => <em>{text}</em>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => (
        <p className="py-1">{children}</p>
      ),
      [BLOCKS.HEADING_1]: (node: any, children: React.ReactNode) => (
        <h1 className="text-info my-4">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node: any, children: React.ReactNode) => (
        <h2 className="text-info my-4">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node: any, children: React.ReactNode) => (
        <h3 className="text-info my-4">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node: any, children: React.ReactNode) => (
        <h4 className="text-info my-4">{children}</h4>
      ),
      [BLOCKS.HEADING_5]: (node: any, children: React.ReactNode) => (
        <h5 className="text-info my-4">{children}</h5>
      ),
      [BLOCKS.HEADING_6]: (node: any, children: React.ReactNode) => (
        <h6 className="text-info my-4">{children}</h6>
      ),
      [BLOCKS.UL_LIST]: (node: any, children: React.ReactNode) => (
        <ul className="list-group my-4">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (node: any, children: React.ReactNode) => (
        <ol className="list-group my-4">{children}</ol>
      ),
      [BLOCKS.LIST_ITEM]: (node: any, children: React.ReactNode) => (
        <li className="list-group-item">{children}</li>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const { description, file } = node.data.target.fields;
        const imageUrl = `https:${file.url}`;
        return (
          <img
            src={imageUrl}
            alt={description}
            className="img-fluid my-4 bg-light"
          />
        );
      },
    },
  };

  const imageSource =
    blogContent && blogContent.fields.image
      ? `https:${blogContent.fields.image.fields.file.url}`
      : "https://i.postimg.cc/5tvb7Kp7/lg.png";

  return (
    <div className="mt">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {blogContent
            ? blogContent.fields.headline + " - AiJobster"
            : "Blog - AiJobster"}
        </title>
        <meta
          name="description"
          content={
            blogContent
              ? blogContent.fields.metadescriptions
              : "Your premier platform for job search in AI companies and remote jobs across top AI-driven organizations."
          }
        />
        <meta
          property="og:title"
          content={blogContent ? blogContent.fields.headline : "Blog"}
        />
        <meta
          property="og:description"
          content={
            blogContent
              ? blogContent.fields.metadescriptions
              : "Your premier platform for job search in AI companies and remote jobs across top AI-driven organizations."
          }
        />
        <meta property="og:image" content={imageSource} />
        <meta property="og:type" content="article" />
        <meta
          name="twitter:title"
          content={blogContent ? blogContent.fields.headline : "Blog"}
        />
        <meta
          name="twitter:description"
          content={
            blogContent
              ? blogContent.fields.metadescriptions
              : "Your premier platform for job search in AI companies and remote jobs across top AI-driven organizations."
          }
        />
        <meta name="twitter:image" content={imageSource} />
      </Helmet>
      <Banner
        title={blogContent !== null ? blogContent.fields.headline : "Blog"}
      />

      <div className="page">
        <div className="container bg-white">
          <a href={"/blog"} className="py-3 d-flex align-items-center">
            <FiArrowLeft className="me-2" />
            Go back to Blog Section
          </a>
          <img
            className="blogImage"
            src={imageSource}
            alt={blogContent !== null ? blogContent.fields.headline : "Blog"}
          />
          {blogContent && (
            <div>
              <h1 className="fw-bold my-4 text-info">
                {blogContent.fields.headline}
              </h1>
              {documentToReactComponents(blogContent.fields.content, options)}
            </div>
          )}
        </div>
      </div>
      <Footer showFilter />
    </div>
  );
};

export default BlogDetails;
