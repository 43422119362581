import React from "react";
import Alert from "react-bootstrap/Alert";

interface ErrorAlertProps {
  error: string;
}

const ErrorAlertComponent: React.FC<ErrorAlertProps> = ({ error }) => {
  return (
    <div className="container">
      <Alert variant="danger mt-4">
        <Alert.Heading>Error</Alert.Heading>
        <p>{error}</p>
      </Alert>
    </div>
  );
};

export default ErrorAlertComponent;
