import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FiBell, FiX } from "react-icons/fi";

interface BannerProps {
  title: string;
}

const Banner: React.FC<BannerProps> = ({ title }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="dark py-5  text-white">
      <div className="container">
        <h1 className="text-capitalize text-center fw-light">{title}</h1>
      </div>
      <button
        onClick={openModal}
        className="btn btn-sm btn-primary rounded mx-auto d-block my-3"
      >
        <FiBell color="#fff" size={16} className="mx-2" />
        <span>Receive Emails for Job Alerts</span>
      </button>

      <Modal show={modalIsOpen} onHide={closeModal} centered>
        <Modal.Header className="modal-bg d-flex justify-content-between align-items-center text-light">
          <Modal.Title>Subscribe to our job emails</Modal.Title>
          <FiX onClick={closeModal} />
        </Modal.Header>
        <Modal.Body className="modal-bg d-flex justify-content-center align-items-center">
          <iframe
            title="External Content"
            src="https://tally.so/r/3xd6xE"
            width="320"
            height="400"
            frameBorder="0"
          ></iframe>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Banner;
