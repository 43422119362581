import React, { useState, useEffect } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";

const NavMenu = () => {
  // State for menu open/close
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to navigate to home
  const hardRefresh = () => {
    window.scrollTo(0, 0);
  };

  // Function to toggle menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // State for scroll position
  const [scrollPosition, setScrollPosition] = useState(0);

  // Effect to update scroll position
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Header style based on scroll position
  const headerStyle: React.CSSProperties = {
    backgroundColor: scrollPosition > 100 ? "#1b1726" : "#1b1b2a",
    transition: "background-color 0.3s ease",
    padding: "20px",
    width: "100%",
    position: "fixed",
    top: 0,
    zIndex: 1000,
  };

  return (
    <div className="nav-menu">
      {/* Side drawer */}
      <div className={`drawer ${isMenuOpen ? "open" : ""}`}>
        <div className="drawer-content">
          <ul>
            <li className="my-3">
              <Link to="/" onClick={toggleMenu}>
                Home
              </Link>
            </li>

            <li className="my-3">
              <Link to="/remote_jobs" onClick={toggleMenu}>
                Remote Jobs
              </Link>
            </li>
            <li className="my-3">
              <Link to="/engineering_jobs" onClick={toggleMenu}>
                Engineering Jobs
              </Link>
            </li>
            <li className="my-3">
              <Link to="/company" onClick={toggleMenu}>
                Top Companies
              </Link>
            </li>
            <li className="my-3">
              <Link to="/about" onClick={toggleMenu}>
                About
              </Link>
            </li>
            <li className="my-3">
              <Link to="/blog" onClick={toggleMenu}>
                Blog
              </Link>
            </li>
            <li className="my-3">
              <Link to="mailto:aijobsterworks@gmail.com">Contact</Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Web Header */}
      <div
        style={headerStyle}
        className="bg fixed-top w-100 d-flex align-items-center p-2"
      >
        <Link to="/" className="d-flex align-items-center col-md-4">
          <img src={logo} alt="AI Jobster" className="logo" />
          <h4 className="mx-2 m-0 text-white fw-bolder">AI Jobster</h4>
        </Link>
        <ul className="d-flex m-0 col-md-8 justify-content-end hide-menu">
          <li className="ms-2">
            <a onClick={hardRefresh} href="/">
              Home
            </a>
          </li>
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/remote_jobs">Remote Jobs</Link>
          </li>
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/engineering_jobs">Engineering Jobs</Link>
          </li>
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/company">Top Companies</Link>
          </li>
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/about">About</Link>
          </li>
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/blog">Blog</Link>
          </li>
          <li className="ms-2">
            <Link to="mailto:aijobsterworks@gmail.com">Contact</Link>
          </li>
        </ul>
      </div>

      {/* Menu toggle button */}
      <div className="menu-toggle" onClick={toggleMenu}>
        {isMenuOpen ? <FiX size={30} /> : <FiMenu size={30} />}
      </div>
    </div>
  );
};

export default NavMenu;
