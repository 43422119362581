import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";

interface Props {
  onSearch: (term: string) => void;
}

const SearchBox: React.FC<Props> = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    // Function to check if the viewport width is less than or equal to 768px (typical mobile breakpoint)
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check on component mount
    checkIfMobile();

    // Event listener to check on resize
    window.addEventListener("resize", checkIfMobile);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleButtonClick = () => {
    onSearch(searchTerm);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch(searchTerm.toLowerCase());
    }
  };

  // Determine placeholder text based on isMobile state
  const getPlaceholder = () => {
    return isMobile
      ? "Search by Company ..."
      : "Search by Company, Location or Tech Skill";
  };

  return (
    <div className="search-box">
      <input
        type="text"
        className="form-control text-smallcase"
        placeholder={getPlaceholder()}
        value={searchTerm}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      <button className="btn" onClick={handleButtonClick}>
        <FiSearch size={20} />
      </button>
    </div>
  );
};

export default SearchBox;
