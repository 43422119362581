import React from "react";
import { AiContent } from "../../utils/types";
import {
  aiBenefitsPageContent,
  aiJobsContent,
  aiLinks,
} from "../../utils/dataSource";
import BenefitAI from "./BenefitAI";
import JobsAI from "./JobsAI";
import Banner from "../CommonComponents/Banner";
import Footer from "../CommonComponents/Footer";
import { FiArrowUpRight } from "react-icons/fi";
import logo from "../../logo.png";

const About: React.FC = () => {
  return (
    <div className="mt">
      <Banner title="About Us" />
      <div className="d-flex flex-wrap align-items-center container  bg-light py-5 mx-auto">
        <div className="col-lg-3">
          <img src={logo} alt="logo" className="img-fluid" />
        </div>
        <div className="col-lg-9">
          <h4 className="fw-light p-5">
            Welcome to AI Jobster, your ultimate resource for AI and
            tech-related job opportunities. At AI Jobster, we are passionate
            about connecting talented individuals with innovative companies at
            the forefront of artificial intelligence and technology.
          </h4>
        </div>
      </div>
      <div className="container mt-5">
        <h2 className="text-info">Our Mission</h2>
        <p>
          Our mission at AI Jobster is to bridge the gap between skilled
          professionals and leading organizations in the field of AI. We strive
          to provide a platform where job seekers can explore exciting career
          opportunities and where employers can find top talent to drive their
          AI initiatives forward.
        </p>

        <hr></hr>
        <div className="pt-1">
          <h4 className="text-info">Benefits of AI Artificial Intelligence</h4>
          <p>
            Benefits of AI Artificial Intelligence (AI) refers to the simulation
            of human intelligence in machines programmed to think, learn, and
            problem-solve like humans. This interdisciplinary field combines
            computer science, mathematics, and cognitive science to create
            intelligent systems capable of tasks that typically require human
            intelligence.
          </p>
        </div>
        <div className="py-1">
          <h4 className="text-info py-4">
            The benefits of AI in technology are vast and impactful:
          </h4>
          <div className="d-flex flex-wrap">
            {aiBenefitsPageContent.map((item: AiContent, index: number) => (
              <BenefitAI key={index} item={item} />
            ))}
          </div>
        </div>
        <div className="py-1">
          <h4 className="text-info py-5 px-5">
            Jobs in AI AI jobs offer a range of benefits, both for individuals
            pursuing careers in the field and for society as a whole. Here are
            some key advantages:
          </h4>
          <div className="d-flex flex-wrap">
            {aiJobsContent.map((item: AiContent, index: number) => (
              <JobsAI key={index} item={item} />
            ))}
          </div>
        </div>
        <hr></hr>
        <h4 className="mb-5 text-info">
          AI Learning Resources and References <FiArrowUpRight />
        </h4>
        <div className="fw-normal mb-5  bg-primary text-white p-5 display-5 rounded">
          <p>
            Looking for a job in AI? Apply to 1000+ jobs at the fastest growing
            AI startups. Programming jobs, design jobs, marketing jobs, sales
            jobs, Frontend or Backend Jobs and more.
          </p>
        </div>
        <ul className="row m-0">
          {aiLinks.map((link, index) => (
            <li key={index} className="col-md-3">
              <a href={link.url} target="_blank" rel="noopener noreferrer">
                {link.name}
              </a>
            </li>
          ))}
        </ul>
        <div className="py-5">
          <p>
            Artificial Intelligence (AI) is a pivotal technological advancement
            that offers a myriad of benefits across diverse sectors. Through its
            machine learning algorithms and robust data analysis capabilities,
            AI revolutionizes various aspects of industry operations. By
            automating repetitive tasks, AI enhances operational efficiency and
            productivity, enabling businesses to allocate resources more
            effectively. Additionally, AI facilitates personalized experiences
            for users, leveraging data insights to provide tailored
            recommendations and targeted advertising. Its prowess in data
            analysis enables organizations to make informed, data-driven
            decisions, paving the way for improved performance and strategic
            planning. Furthermore, AI drives advancements in predictive
            analytics, empowering businesses to anticipate future trends and
            mitigate potential risks. In sectors like healthcare, AI plays a
            critical role in diagnostics, drug discovery, and personalized
            treatment plans, leading to enhanced patient care and outcomes.
            Moreover, AI fuels innovation in autonomous vehicles, cybersecurity,
            and creative fields such as art and music. This transformative
            technology not only creates a wealth of career opportunities but
            also fosters a culture of continuous learning and collaboration,
            driving progress and innovation in the global AI landscape.
          </p>
        </div>
      </div>
      <Footer showFilter />
    </div>
  );
};

export default About;
