import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Banner from "../CommonComponents/Banner";
import ListItem from "./ListItem";
import Footer from "../CommonComponents/Footer";
import ErrorAlertComponent from "../CommonComponents/ErrorAlertComponent";
import SpinnerComponent from "../CommonComponents/SpinnerComponent";
import { FiArrowLeft } from "react-icons/fi";

interface Job {
  ai_category: string;
  category: string[];
  company_logo: string;
  company_name: string;
  company_url: string;
  date_posted: string;
  department: string;
  is_remote: string;
  job_title: string;
  job_url: string;
  max_salary: number;
  min_salary: number;
  skills: string[];
  slug_url: string | null; // Updated to match ListItem interface
  work_location: string[];
}

interface Company {
  ai_category: string[];
  company_name: string;
  company_url: string;
  employees: string;
  fund: string;
  fund_stage: string;
  hq: string;
  year_founded: number;
}

interface Department {
  department: string;
  jobs: Job[];
}

interface CompanyData {
  company: Company;
  jobs: Department[];
}

const CompanyDetails: React.FC = () => {
  const { companyName } = useParams<{ companyName: string }>();
  const location = useLocation();
  const state = location.state as { value?: string }; // Type assertion

  // Use value from state if it exists, otherwise fallback to companyName
  const value = state?.value || companyName;
  const [data, setData] = useState<CompanyData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [value]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_COMPANY_LISTING_API!}${value}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [value]);

  return (
    <div className="mt">
      <Banner title={"Company Details"} />
      {loading ? (
        <SpinnerComponent animation="border" variant="primary" />
      ) : error ? (
        <ErrorAlertComponent error={error} />
      ) : (
        <div className="container">
          <a href={"/company"} className="py-3 d-flex align-items-center">
            <FiArrowLeft className="me-2" /> View More Companies
          </a>
          <div className="mt-2">
            {data?.company && (
              <ListItem jobs={data.jobs ?? []} company={data.company} />
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default CompanyDetails;
