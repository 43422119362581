import React from "react";
import Spinner from "react-bootstrap/Spinner";
import { quotes } from "../../utils/dataSource"; // Ensure this path is correct

interface SpinnerProps {
  animation?: "border" | "grow";
  variant: string;
}

const SpinnerComponent: React.FC<SpinnerProps> = ({
  animation = "border",
  variant = "primary",
}) => {
  const randomIndex = Math.floor(Math.random() * quotes.length);
  const randomQuote = quotes[randomIndex];

  return (
    <div className="d-flex container justify-content-center align-items-center ht">
      <div className="text-center">
        <Spinner animation={animation} variant={variant} />
        <div className="d-flex flex-column text-dark">
          <span style={{ fontSize: 14 }} className="mt-3 p-4">
            {randomQuote.quote}
          </span>
          <small style={{ fontSize: 10 }}>Author - {randomQuote.author}</small>
        </div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
