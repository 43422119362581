import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/HomePage/Home";
import About from "./components/AboutPage/About";
import Engineering from "./components/EngineeringPage/Engineering";
import Company from "./components/CompanyPage/Company";
import NavMenu from "./components/CommonComponents/NavMenu";
import CompanyDetails from "./components/CompanyPage/CompanyDetails";
import NotFoundPage from "./components/CommonComponents/NotFoundPage";
import ReactGA from "react-ga4";
import { gaTag } from "./utils/types";
import JobBackend from "./components/HomePage/JobBackend";
import Blog from "./components/BlogPage/Blog";
import BlogDetails from "./components/BlogPage/BlogDetails";
import Remote from "./components/RemotePage/Remote";
import JobDetails from "./components/HomePage/JobDetails";

const App: React.FC = () => {
  useEffect(() => {
    ReactGA.initialize(gaTag);
    ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
    ReactGA.send({
      hitType: "pageview",
      page: "/engineering_jobs",
      title: "Engineering Page",
    });
    ReactGA.send({
      hitType: "pageview",
      page: "/company",
      title: "Company Page",
    });
    ReactGA.send({
      hitType: "pageview",
      page: "/company/:companyName",
      title: "Company Details",
    });
  }, []);

  return (
    <Router>
      <div>
        <NavMenu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/engineering_jobs" element={<Engineering />} />
          <Route path="/remote_jobs" element={<Remote />} />
          <Route path="/company" element={<Company />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:heading" element={<BlogDetails />} />
          <Route path="/company/:companyName" element={<CompanyDetails />} />
          <Route path="/jobs/api" element={<JobBackend />} />
          <Route path="/jobs/:companyName/:value" element={<JobDetails />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
