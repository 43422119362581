// Import necessary modules
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Banner from "../CommonComponents/Banner";
import Footer from "../CommonComponents/Footer";
import JobListing from "./JobListing";
import { useLocation } from "react-router-dom";
import SearchBox from "../CommonComponents/SearchBox";
import Pagination from "../CommonComponents/Pagination"; // Import Pagination component
import SpinnerComponent from "../CommonComponents/SpinnerComponent";
import ErrorAlertComponent from "../CommonComponents/ErrorAlertComponent";
import {
  FiAlertTriangle,
  FiArrowUpRight,
  FiRefreshCcw,
  FiX,
} from "react-icons/fi";

// Define Job interface
interface Job {
  job_title: string;
  company_name: string;
  ai_category: string;
  category: string[];
  company_logo: string;
  company_url: string;
  date_posted: string;
  department: string;
  is_remote: string;
  job_url: string;
  max_salary: number | null;
  min_salary: number | null;
  skills: string[];
  slug_url: string | null;
  work_location: string[];
}

// Define Home component
const Home: React.FC = () => {
  const [jobListings, setJobListings] = useState<Job[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalJobs, setTotalJobs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchParam = queryParams.get("search");
  const departmentParam = queryParams.get("department");
  const [tempSearchTerm, setTempSearchTerm] = useState(searchParam || "");
  const [show, setShow] = useState(false);
  const pageSize = 20;

  // Update tempSearchTerm when searchParam changes
  useEffect(() => {
    if (searchParam !== null) {
      setTempSearchTerm(searchParam);
    }
  }, [searchParam]);

  // Fetch data function
  const fetchData = useCallback(async () => {
    setLoading(true);
    window.scrollTo(0, 0);
    try {
      const response = await axios.post(
        process.env.REACT_APP_HOME_JOBS_API!,
        {
          search: tempSearchTerm ? [tempSearchTerm] : null,
          departments: departmentParam ? [departmentParam] : null,
          page_size: pageSize,
          page: currentPage - 1, // Adjusted for zero-based index
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { jobs, count } = response.data;
      setJobListings(jobs || []);
      setTotalJobs(count || 0);
      setError(null);
    } catch (error) {
      setError("Error fetching data");
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  }, [tempSearchTerm, departmentParam, pageSize, currentPage]);

  // useEffect hook to fetch data when component mounts or dependencies change
  useEffect(() => {
    if (departmentParam && tempSearchTerm === "") {
      window.scrollTo(0, 0);
      setLoading(true);
      fetchData();
    }
  }, [departmentParam, tempSearchTerm, fetchData]);

  useEffect(() => {
    fetchData();
  }, [currentPage, departmentParam, tempSearchTerm, fetchData]);

  // Function to handle search
  const handleSearch = (term: string) => {
    setTempSearchTerm(term);
    setCurrentPage(1); // Reset page to 1 when performing a new search
  };

  // Render the component
  return (
    <div className="mt">
      {/* SEO-friendly page title */}
      <title>
        AIJobster - Find Your Dream Job, remote ai jobs in AI companies.
      </title>
      <Banner title="Welcome to AI Jobster" />
      <div>
        {loading ? (
          <SpinnerComponent animation="border" variant="primary" />
        ) : error ? (
          <ErrorAlertComponent error={error} />
        ) : (
          <>
            <div className="vh-50">
              <div className="dark py-2">
                <h3 className="text-center text-white">
                  {totalJobs} Jobs Found
                </h3>
                <SearchBox onSearch={handleSearch} />
                {tempSearchTerm && (
                  <div className="container">
                    <p className="text-center text-white my-3">
                      Results for "{tempSearchTerm}"
                    </p>
                  </div>
                )}
                {departmentParam && (
                  <div className="container">
                    <p className="text-center text-white my-3">
                      Results for "{departmentParam}"
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="container">
              <div className="col-md-8 mx-auto my-4">
                <div className="d-flex">
                  <h4 className="text-info">Why Choose Us?</h4>
                  {!show ? (
                    <FiArrowUpRight
                      size={20}
                      color="#0cf"
                      className="mx-2 text-info"
                      onClick={() => setShow(!show)}
                    />
                  ) : (
                    <FiX
                      size={20}
                      color="#0cf"
                      className="mx-2 text-info"
                      onClick={() => setShow(!show)}
                    />
                  )}
                </div>
                {show && (
                  <>
                    <p className="text-secondary">
                      Our platform, AIJobster, is dedicated to revolutionizing
                      the job search experience through cutting-edge AI
                      technology. With a focus on connecting job seekers with
                      opportunities that align perfectly with their skills and
                      ambitions, we leverage sophisticated algorithms to
                      streamline the recruitment process. By harnessing
                      AI-driven insights, we empower employers to access a rich
                      and diverse talent pool, facilitating informed hiring
                      decisions with unparalleled efficiency.
                    </p>
                    <p className="text-secondary">
                      At AIJobster, we transcend the traditional notion of a job
                      board. We've created a dynamic ecosystem where talent
                      intersects with opportunity in the digital era. Whether
                      you're a job seeker eager to explore new horizons or an
                      employer seeking top-tier talent, AIJobster is your
                      gateway to the future of recruitment. Join us today and
                      discover how AIJobster is reshaping the landscape of
                      talent acquisition and job hunting, one connection at a
                      time.
                    </p>
                  </>
                )}
              </div>
            </div>

            <div className="container mt-2">
              {jobListings.length !== 0 ? (
                jobListings.map((job, index) => (
                  <JobListing key={index} job={job} />
                ))
              ) : (
                <div className="d-flex py-5  flex-column justify-content-center align-items-center">
                  <FiAlertTriangle size={40} color="tomato" />
                  <h4 className="text-danger mb-5 mt-3">No results found.</h4>
                  <a href="/">
                    <FiRefreshCcw /> Refresh
                  </a>
                </div>
              )}
            </div>
            {/* Pagination component */}
            <Pagination
              currentPage={currentPage}
              totalJobs={totalJobs}
              pageSize={pageSize}
              onPageChange={setCurrentPage}
            />
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Home; // Export the Home component
