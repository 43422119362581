import React from "react";
import { AiContent } from "../../utils/types";

interface Props {
  item: AiContent;
}

const JobsAI: React.FC<Props> = ({ item }) => {
  return (
    <div className="box">
      <div className="p-4 rounded bg-light">
        <h6 className="fw-bold">{item.title}:</h6>
        <p>{item.description}</p>
      </div>
    </div>
  );
};

export default JobsAI;
